import React from 'react';
import { Link } from 'gatsby';
import SEO from '/src/components/atoms/Seo';
import ContentSection from '/src/components/atoms/ContentSection';
import Button from '/src/components/atoms/Button';
import Page from '/src/components/organisms/Page';

const NotFound = () => (
  <Page title="Page Not Found - NCC Akron Dance Chat" customHeader>
    <SEO title="404: Not found" />
    <ContentSection>
      <div className="content-outer">
        <div className="content-inner">
          <h1 className="heading1 space-bottom">Page not found</h1>
          <p className="space-top tc">
            <Button component={Link} to="/">
              Back to Home
            </Button>
          </p>
        </div>
      </div>
    </ContentSection>
  </Page>
);

export default NotFound;
